import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import { Cart, SearchBox } from "../components";
import { SiteContext } from "../context/index";

const Header = () => {
  const {
    isLoggedOn,
    handleSignOut,
    getOrderItemsTotal,
    currentPage,
    handleCurrentPage,
    apiUrl,
    siteconfig,
    isMobile,
    singleProduct,
  } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState("");
  const [mType, setMType] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    if (currentPage !== "") setPage(currentPage);
  }, [currentPage]);

  const doSignOut = () => {
    navigate("/");
    handleSignOut();
  };

  const switchModal = (item) => {
    if (item === "menu") {
      setMType("menu");
    } else if (item === "cart") {
      setMType("cart");
    } else if (item === "search") {
      setMType("search");
    } else if (item === "user") {
      setMType("user");
    }
    setShowModal(true);
  };

  const HeaderMenu = () => {
    return (
      <div className="bg-dark">
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/"
          >
            Home
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/company"
          >
            Company
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/our-services"
          >
            Services
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/partners"
          >
            Partners
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/projects"
          >
            Projects
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/portfolio"
          >
            Portfolio
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/contact"
          >
            Contact
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/request-quote"
          >
            Request a quote
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            onClick={() => switchModal("search")}
            to=""
          >
            Search
          </Link>
        </div>
        {isLoggedOn && (
          <div>
            <Link
              className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
              onClick={() => doSignOut()}
              to=""
            >
              Log out
            </Link>
          </div>
        )}
      </div>
    );
  };

  const UserMenu = () => {
    return (
      <div>
        {userData ? (
          <h4 className="py-1 border-bottom">Hello {userData.name}</h4>
        ) : (
          <h4 className="py-1 border-bottom">Hello Guest</h4>
        )}
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/user">
            My Account
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? 0 : "25%",
            bottom: isMobile ? 0 : "25%",
            left: isMobile ? 0 : "20%",
            right: isMobile ? 0 : "20%",
            width: isMobile ? "100%" : "60%",
            height: isMobile ? "100%" : "50&",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "10px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-dark mb-2 py-1"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x h3 text-white"></i>
            </span>
          </div>
          {mType === "menu" && <HeaderMenu />} {mType === "cart" && <Cart />}{" "}
          {mType === "search" && <SearchBox />}{" "}
          {mType === "user" && <UserMenu />}
        </div>
      </ReactModal>

      <div
        style={{
          backgroundColor: "white",
          height: isMobile ? 70 : currentPage === "home" ? 120 : 150,
        }}
        className="container"
      >
        <div className="row">
          <div className="col-md-3 col-4">
            <div
              className={
                isMobile
                  ? "col-6 d-block d-md-none py-2"
                  : "col-md-3 d-none d-md-block py-2"
              }
            >
              <Link to="/">
                <img
                  src={`${apiUrl}/${siteconfig.shoplogo}`}
                  height={isMobile ? 60 : 70}
                  width={isMobile ? 100 : 150}
                  alt="Logo"
                />
              </Link>
            </div>
          </div>
          <div className="col-md-9 col-8 d-flex justify-content-end align-items-center">
            <div className="col-md-12 d-md-block d-none">
              <div className="row">
                <div className="col-md-5">
                  <div className="row">
                    <div className={isMobile ? "col-1" : "col-2"}>
                      <i className="bi bi-phone text-center h2"></i>
                    </div>
                    <div className="col small">
                      <span className="text-muted fst-italic">
                        GENERAL ENQUIRY
                      </span>
                      <br />
                      <span className="h6">{siteconfig.shopphone}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className={isMobile ? "col-1" : "col-2"}>
                      <i className="bi bi-clock text-center h2"></i>
                    </div>
                    <div className="col small">
                      <span className="text-muted fst-italic">
                        WORKING TIME
                      </span>
                      <br />
                      <span className="h6">MON-SAT: 08:00-18:00</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 d-flex align-items-center justify-content-end">
                  <button
                    style={{ backgroundColor: "#5f51bd" }}
                    className="btn btn-sm px-2 py-2 h6 small text-decoration-none border-0 rounded-2 text-white"
                    onClick={() => {
                      navigate("/request-quote");
                      handleCurrentPage("");
                    }}
                  >
                    <i className="bi bi-envelope px-1"></i> REQUEST A QUOTE
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12 d-block d-md-none">
              <div className="d-flex justify-content-end align-content-center">
                <button
                  className="px-2 d-none text-decoration-none btn btn-transparent text-dark"
                  onClick={() => switchModal("search")}
                >
                  <i className="bi bi-search h2"></i>
                </button>

                {isLoggedOn && (
                  <div className="py-1">
                    <span className="px-1">
                      <button
                        className="px-2 py-1 rounded-pill bg-dark text-white"
                        onClick={() => switchModal("user")}
                      >
                        {userData !== null ? (
                          <span>
                            <img
                              src={`${apiUrl}/${userData.photo}`}
                              height={20}
                              width={20}
                              className="rounded-circle"
                              alt={userData.name}
                            />{" "}
                            <i className="bi bi-chevron-down h6"></i>
                          </span>
                        ) : (
                          <i className="bi bi-person"></i>
                        )}{" "}
                      </button>
                    </span>
                    <span className="px-1">
                      <button
                        className="px-2 d-none py-1 rounded bg-dark text-white"
                        onClick={() => doSignOut()}
                      >
                        <i className="bi bi-box-arrow-right h6"></i>
                      </button>
                    </span>
                  </div>
                )}
                <span className="px-2">
                  <button
                    className="px-2 text-decoration-none btn btn-dark text-white"
                    onClick={() => switchModal("menu")}
                  >
                    <i className="bi bi-list text-white h4"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#5f51bd",
            position: "relative",
            bottom: page === "home" ? -15 : -20,
            height: 50,
            top: 15,
            marginBottom: page === "home" ? -15 : 20,
            zIndex: 100,
          }}
          className="container rounded-2 text-white d-md-block d-none"
        >
          <div className="row py-2">
            <div className="col-md-8">
              <div className="d-flex justify-content-start align-content-center py-1">
                <div className="d-none d-md-block">
                  <Link
                    className={
                      page === "home"
                        ? "py-3 px-2 mx-2 text-decoration-none bg-dark text-white"
                        : "py-3 px-2 text-decoration-none text-white"
                    }
                    onClick={() => handleCurrentPage("home")}
                    to="/"
                  >
                    HOME
                  </Link>
                  <Link
                    className={
                      page === "company"
                        ? "py-3 px-2 text-decoration-none bg-dark text-white"
                        : "py-3 px-2 text-decoration-none text-white"
                    }
                    onClick={() => handleCurrentPage("company")}
                    to="/company"
                  >
                    COMPANY
                  </Link>
                  <Link
                    className={
                      page === "services"
                        ? "py-3 px-2 text-decoration-none bg-dark text-white"
                        : "py-3 px-2 text-decoration-none text-white"
                    }
                    onClick={() => handleCurrentPage("services")}
                    to="/our-services"
                  >
                    SERVICES
                  </Link>
                  <Link
                    className={
                      page === "projects"
                        ? "py-3 px-2 text-decoration-none bg-dark text-white"
                        : "py-3 px-2 text-decoration-none text-white"
                    }
                    onClick={() => handleCurrentPage("projects")}
                    to="/projects"
                  >
                    PROJECTS
                  </Link>
                  <Link
                    className={
                      page === "partners"
                        ? "py-3 px-2 text-decoration-none bg-dark text-white"
                        : "py-3 px-2 text-decoration-none text-white"
                    }
                    onClick={() => handleCurrentPage("partners")}
                    to="/partners"
                  >
                    PARTNERS
                  </Link>
                  <Link
                    className={
                      page === "portfolio"
                        ? "py-3 px-2 text-decoration-none bg-dark text-white"
                        : "py-3 px-2 text-decoration-none text-white"
                    }
                    onClick={() => handleCurrentPage("portfolio")}
                    to="/portfolio"
                  >
                    PORTFOLIO
                  </Link>
                  <Link
                    className={
                      page === "contact"
                        ? "py-3 px-2 text-decoration-none bg-dark text-white"
                        : "py-3 px-2 text-decoration-none text-white"
                    }
                    onClick={() => handleCurrentPage("contact")}
                    to="/contact"
                  >
                    CONTACT
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-end align-content-center">
                <button
                  className="px-2 text-decoration-none btn btn-transparent btn-sm text-white"
                  onClick={() => switchModal("search")}
                >
                  <i className="bi bi-search h3"></i>
                </button>

                {isLoggedOn && (
                  <div className="py-1">
                    <span className="px-1">
                      <button
                        className="px-2 rounded-pill bg-dark text-white"
                        onClick={() => switchModal("user")}
                      >
                        {userData !== null ? (
                          <span>
                            <img
                              src={`${apiUrl}/${userData.photo}`}
                              height={20}
                              width={20}
                              className="rounded-circle"
                              alt={userData.name}
                            />{" "}
                            <i className="bi bi-chevron-down h6"></i>
                          </span>
                        ) : (
                          <i className="bi bi-person"></i>
                        )}{" "}
                      </button>
                    </span>
                    <span className="px-1">
                      <button
                        className="px-2 rounded bg-dark text-white"
                        onClick={() => doSignOut()}
                      >
                        <i className="bi bi-box-arrow-right h6"></i>
                      </button>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
