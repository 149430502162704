import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";
import { aboutImage, localTerrain } from "../assets";
import { removeTags, getHeading } from "../utils";
import Carousel from "react-bootstrap/Carousel";
import Accordion from "react-bootstrap/Accordion";
import Seo from "./Seo";

const Home = () => {
  const {
    userData,
    apiUrl,
    siteconfig,
    isLoggedOn,
    categories,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const navigate = useNavigate();

  const [animationPhotos, setAnimationPhotos] = useState([]);
  const [stories, setStories] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [clientLogos, setClientLogos] = useState([]);
  const [homeServices, setHomeServices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHomeAnimations();
    getClientLogos();
    getServices();
    handleCurrentPage("home");
  }, []);

  const getHomeAnimations = () => {
    fetch(`${apiUrl}/api/homeanimations.php?action=fetch&type=Shop`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setAnimationPhotos(res.rows);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClientLogos = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchallpages&section=Clients`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setClientLogos(res.rows);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getServices = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchallpages&section=Services`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          const homeservices = res.rows.filter(
            (item) => item.cpagename !== "Services Overview"
          );
          setHomeServices(homeservices);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const processText = (text) => {
    let thetext = removeTags(text);

    thetext = getHeading(thetext, 25);

    return thetext;
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-content-center my-5">
        <div class="spinner-border text-secondary" role="status" />
      </div>
    );
  }

  return (
    <div>
      <Seo
        title={`Home | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />

      {animationPhotos.length > 0 && (
        <Carousel style={{ backgroundColor: "black" }}>
          {animationPhotos.map((item) => {
            return (
              <Carousel.Item interval={2000} key={item.phid}>
                <img
                  style={{ objectFit: "fill" }}
                  className="d-block w-100"
                  src={`${apiUrl}/${item.phphoto}`}
                  height={isMobile ? 400 : 600}
                  width="100%"
                  alt={item.phtitle}
                />
                <div
                  className="mt-0 p-4 rounded"
                  style={{
                    padding: "5% !important",
                    color: "#fff",
                    position: "absolute",
                    backgroundColor: "#333",
                    opacity: 0.8,
                    left: isMobile ? "5%" : "10%",
                    bottom: isMobile ? "10%" : "40%",
                    width: isMobile ? "90%" : "40%",
                  }}
                >
                  <p className={`text-white ${isMobile ? "h2" : "h1"}`}>
                    {item.phtitle}
                  </p>
                  <p className={isMobile ? "h6" : "h5"}>{item.phcontent}</p>
                  <div className="my-2 d-none d-md-block">
                    <button
                      style={{ backgroundColor: "#5f51bd" }}
                      className="rounded border-0 text-white p-2"
                      onClick={() => {
                        handleCurrentPage("services");
                        navigate("/our-services");
                      }}
                    >
                      Our Services
                    </button>{" "}
                    <button
                      style={{ backgroundColor: "#eeeeee" }}
                      className="rounded border-0 text-dark p-2 mx-2"
                      onClick={() => {
                        handleCurrentPage("company");
                        navigate("/company");
                      }}
                    >
                      About Us
                    </button>
                  </div>
                </div>
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}

      <div className="container">
        <div className="rounded my-1 py-3 border border-light">
          <div className="row p-3">
            {homeServices.map((item) => {
              return (
                <div key={item.cpageid} className="col-md-4 col-sm-12 p-3">
                  <div className="border-primary border-3 shadow border-top text-dark p-2 rounded">
                    <img
                      style={{ objectFit: "contain", height: 300 }}
                      src={`${apiUrl}/${item.cpagephoto}`}
                      height={300}
                      width="100%"
                    />
                    <div className="p-3">
                      <h4>{item.cpagename}</h4>
                      <div
                        style={{ height: isMobile ? 200 : 220 }}
                        className="row d-flex align-content-between"
                      >
                        <div>
                          <p className="text-muted">
                            {processText(item.cpagecontent)}
                          </p>
                        </div>
                        <div>
                          <button
                            style={{ backgroundColor: "#5f51bd" }}
                            onClick={() =>
                              navigate(`/our-services/${item.cpagelinkname}`, {
                                state: { service: item },
                              })
                            }
                            className="rounded my-3 border-0 text-white py-2 px-4"
                          >
                            Read more
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        style={{ backgroundColor: "azure" }}
        className={isMobile ? "px-2 py-4 my-3" : "px-3 py-5 my-3"}
      >
        <div className="container py-3">
          <div className="row">
            <div className="col-md-6 p-4">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>WHO WE ARE</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      SER is a wholly indigenous company with strategic
                      technical alliances with OEMs and renowned service
                      providers, collaborating to provide best in class
                      solutions to the local O&G sector.
                    </p>

                    <p>
                      These solutions are implemented while strictly adhering to
                      global health, safety and environmental standards. Our
                      goal is to provide optimum and quality service solutions,
                      utilizing cutting edge technology, operational efficiency
                      and local manpower while ensuring development of local
                      human capital and technology transfer from foreign
                      partners.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>MISSION</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Subterra Energy Resources (SER) is committed to providing
                      the Energy sector particularly the Oil and Gas sector with
                      value added solutions, tailored to each client's
                      challenges in the most cost effective, safe and
                      environmentally friendly manner while ensuring excellent
                      corporate social responsibilities.
                    </p>

                    <p>
                      SER aims to provide value added solutions to the O & G
                      sector by utilizing the vast international experience of
                      its technical and support staff leveraging this on a
                      strong local industry knowledge to produce world class
                      services.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-md-6">
              <img
                className="rounded img-fluid"
                src={aboutImage}
                width="100%"
                height={isMobile ? 250 : 350}
                alt="about us"
              />
            </div>
          </div>
        </div>
      </div>

      <ProjectGallery />

      <div
        style={{ backgroundColor: "#5f51bd" }}
        className="px-3 py-5 my-3 text-white"
      >
        <div className="container py-3">
          <div className="row">
            <div className="col-md-6 text-center">
              <img
                className="rounded"
                src={localTerrain}
                width={isMobile ? "80%" : "70%"}
                height={isMobile ? 250 : 400}
                alt="skilled manpower"
              />
            </div>
            <div className="col-md-6 p-4">
              <h2 className="px-2">We hire skilled manpower for the terrain</h2>

              <p className="px-2">
                We do not compromise when it comes to getting the right hands
                for the job. Local and adequate knowledge of the project site is
                a must in order to execute projects within agreed timeframe and
                cost.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white border-bottom my-4">
        <div className="container">
          <h2 className="py-3">Some of our clients</h2>
          <div className="bg-light text-center">
            {clientLogos &&
              clientLogos.map((item, i) => {
                return (
                  <img
                    style={{ objectFit: "contain", height: "100px" }}
                    className="img-fluid mx-1 p-3 rounded"
                    src={`${apiUrl}/${item.cpagephoto}`}
                    height={100}
                    width={150}
                    alt={item.cpagename}
                  />
                );
              })}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
