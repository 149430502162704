import React, { useState, useEffect, useContext } from "react";
import { NavigationBar } from "../components";
import moment from "moment";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { removeTags, getHeading } from "../utils";
import { SiteContext } from "../context";
import { Header, Footer, Seo } from "./";

const Portfolio = () => {
  const navigate = useNavigate();

  let { title } = useParams();
  let { service } = useLocation().state || {};

  const { apiUrl, pages, siteconfig, isMobile, handleCurrentPage } =
    useContext(SiteContext);

  const [content, setContent] = useState({});
  const [pageServices, setPageServices] = useState([]);

  useEffect(() => {
    getPageServices();
  }, []);

  useEffect(() => {
    doViewsUpdate();
    window.scrollTo(0, 0);
  }, [title]);

  const getPageServices = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchallpages&section=Clients`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setPageServices(res.rows);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const processText = (text) => {
    let thetext = removeTags(text);

    thetext = getHeading(thetext, 25);

    return thetext;
  };

  const doViewsUpdate = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchsingleservice&id=${title}`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setContent(res.page);
        } else if (res.status === 400) {
          console.log(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Header />
      {content.hasOwnProperty("cpageid") && (
        <Seo
          title={content.cpagename + " | " + siteconfig.shopname}
          description={processText(content.cpagecontent)}
          url={`${apiUrl}/portfolio/${title}`}
          img={`${apiUrl}/${siteconfig.shoplogo}`}
        />
      )}

      <div className="container my-2 p-3">
        <NavigationBar
          section={"Portfolio"}
          page={content.cpagename}
          goback={true}
        />

        <div className="row">
          <div className="col-md-8">
            {content && content.hasOwnProperty("cpageid") && (
              <div className="my-1 py-2 rounded">
                <h3 className="py-2">{content.cpagename}</h3>

                <img
                  style={{ objectFit: "cover", height: isMobile ? 250 : 350 }}
                  className="my-2"
                  src={`${apiUrl}/${content.cpagephoto}`}
                  height={250}
                  width="100%"
                  alt={content.cpagename}
                />
                <br />

                <div
                  className="py-2"
                  dangerouslySetInnerHTML={{ __html: content.cpagecontent }}
                />
              </div>
            )}
          </div>
          <div className="col-md-4 bg-light mt-2">
            <h4 className="bg-dark text-white rounded border-bottom p-2">
              Portfolio
            </h4>
            {pageServices.length > 0 &&
              pageServices.map((page, i) => {
                return (
                  <div
                    key={i}
                    className="text-decoration-none border-bottom my-1"
                  >
                    <Link
                      className="text-dark text-decoration-none"
                      to={`/portfolio/${page.cpagelinkname}`}
                    >
                      <div className="p-2">{page.cpagename}</div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
