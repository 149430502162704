import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { badge } from "../assets";
import { SiteContext } from "../context";
import moment from "moment";

const Footer = () => {
  const { siteconfig, apiUrl, categories, handleCurrentPage } =
    useContext(SiteContext);

  const [itemSelected, setItemSelected] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleShow = (name, url) => {
    let slaccount = {
      name,
      url,
    };
    setItemSelected(slaccount);
    setShowModal(true);
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "65%",
            bottom: "10%",
            left: "5%",
            right: "5%",
            width: "90%",
            height: "25%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div className="row">
          <div className="col-10">
            <p className="h3 my-1 py-1">{itemSelected.name}</p>
            <a
              href={itemSelected.url}
              target="_blank"
              className="my-1 py-2 text-decoration-none"
            >
              {itemSelected.url}
            </a>
          </div>
          <div className="col-2">
            <span
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              x
            </span>
          </div>
        </div>
      </ReactModal>
      <div className="text-dark" style={{ backgroundColor: "#eee" }}>
        <div className="container">
          <div className="row p-2">
            <div className="col-md-5 my-1 d-flex justify-content-center align-content-center">
              <div className="w-100">
                <img
                  className="img-fluid p-2"
                  src={`${apiUrl}/${siteconfig.shoplogo}`}
                  height="auto"
                  width={150}
                  alt="logo"
                />

                <p className="my-2">{siteconfig.shopdescription}</p>

                <a
                  href="http://discovery.ariba.com/profile/AN11011412539"
                  target={"_blank"}
                >
                  <img
                    className="img-fluid p-2"
                    src={badge}
                    height={100}
                    width={150}
                    alt="badge"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-2 my-1">
              <p className="h5">Links</p>
              <div
                style={{ width: 30 }}
                className="border-bottom border-3 border-danger"
              />
              <div className="my-2">
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    onClick={() => handleCurrentPage("services")}
                    to="/our-services"
                  >
                    Services
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    onClick={() => handleCurrentPage("projects")}
                    to="/projects"
                  >
                    Projects
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    onClick={() => handleCurrentPage("partners")}
                    to="/partners"
                  >
                    Partners
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    onClick={() => handleCurrentPage("portfolio")}
                    to="/portfolio"
                  >
                    Portfolio
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    onClick={() => handleCurrentPage("company")}
                    to="/company"
                  >
                    Company
                  </Link>
                </p>
              </div>
            </div>

            <div className="col-md-2 my-1">
              <p className="h5">Support</p>
              <div
                style={{ width: 30 }}
                className="border-bottom border-3 border-danger"
              />

              <div className="my-2">
                <p>
                  <Link
                    className="text-dark text-decoration-none py-2"
                    onClick={() => handleCurrentPage("")}
                    to="/page/feedback"
                  >
                    Feedback
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-2"
                    onClick={() => handleCurrentPage("contact")}
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </p>
              </div>
              {(siteconfig.slfacebook !== "" ||
                siteconfig.sltwitter !== "" ||
                siteconfig.slinstagram !== "" ||
                siteconfig.slyoutube !== "") && (
                <p className="h5 mt-5 my-1">Follow us on</p>
              )}
              {siteconfig.slfacebook != "" && (
                <span className="p-1 my-1">
                  <button
                    className="py-1 rounded-circle"
                    onClick={() =>
                      handleShow("facebook", siteconfig.slfacebook)
                    }
                  >
                    <i className="bi bi-facebook"></i>
                  </button>
                </span>
              )}
              {siteconfig.sltwitter != "" && (
                <span className="p-1 my-1">
                  <button
                    className="py-1 rounded-circle"
                    onClick={() => handleShow("twitter", siteconfig.sltwitter)}
                  >
                    <i className="bi bi-twitter"></i>
                  </button>
                </span>
              )}
              {siteconfig.slinstagram != "" && (
                <span className="p-1 my-1">
                  <button
                    className="py-1 rounded-circle"
                    onClick={() =>
                      handleShow("instagram", siteconfig.slinstagram)
                    }
                  >
                    <i className="bi bi-instagram"></i>
                  </button>
                </span>
              )}
              {siteconfig.slyoutube != "" && (
                <span className="p-1 my-1">
                  <button
                    className="py-1 rounded-circle"
                    onClick={() => handleShow("youtube", siteconfig.slyoutube)}
                  >
                    <i className="bi bi-youtube"></i>
                  </button>
                </span>
              )}
            </div>

            <div className="col-md-3 my-1">
              <p className="h5">Contact</p>
              <div
                style={{ width: 30 }}
                className="border-bottom border-3 border-danger"
              />
              <div className="my-2">
                <span className="p-1">
                  Benin City: 21 Benoni Road
                  <br />
                  Off Airport Road GRA
                  <br />
                  Benin City, Edo State
                  <br />
                  <br />
                  Lagos: 10b Osaro Isokpan Crescent
                  <br />
                  Off Fola Osibo, Off Kafayata Abdulrazaq
                  <br />
                  Lekki Phase 1, Lagos State
                  <br />
                  <br />
                  Houston: Suite B101, Oxford Plaza Suites
                  <br />
                  12440 Oxford Park Drive
                  <br />
                  Houston, TX77082, USA
                </span>
                <br /> <br />
                <span className="p-1">
                  <i className="bi bi-phone"></i>: {siteconfig.shopphone}
                </span>
                <br />
                <br />
                <span className="p-1">
                  <i className="bi bi-envelope"></i>:{" "}
                  {siteconfig.shopemailaddress}
                </span>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-dark py-1 text-white small">
          <div className="container d-flex justify-content-between">
            <p className="py-1 font-weight-bold">
              Copyright{" "}
              <Link className="text-white text-decoration-none" to="/">
                {siteconfig.shopname}
              </Link>{" "}
              {moment().format("YYYY")} |{" "}
              <Link
                className="text-white text-decoration-none"
                to="/page/privacy-policy"
              >
                Privacy Policy
              </Link>{" "}
            </p>
            <p className="py-1 font-weight-bold">
              Developed by{" "}
              <a
                className="text-white text-decoration-none"
                href="https://justwebservices.com"
                target={"_blank"}
              >
                Just Web Services
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
