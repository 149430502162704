import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {Header, Footer} from './';
import { SiteContext } from '../context';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    username: Yup.string()
      .label('Username')
      .required()
      .min(4, 'Must have at least 4 characters'),
    email: Yup.string()
      .label('Email')
      .email('Enter a valid email')
      .required('Please enter a registered email'),
    category: Yup.string()
      .label('Category')
      .required('Please select a category'),
    password: Yup.string()
      .label('Password')
      .required()
      .min(5, 'Password must have at least 5 characters '),
    password2: Yup.string()
      .oneOf([Yup.ref('password')], 'Confirm Password must match Password')
      .required('Confirm Password is required'),
    agree: Yup.boolean().oneOf([true], 'Please check the agreement')
  });

const Signup = () => {
  const [duplicates, setDuplicates] = useState([]);
  const [duplicatesMsg, setDuplicatesMsg] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [rkey, setRkey] = useState('');
  const [useremail, setUseremail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [msg, setMsg] = useState('');

    const {apiUrl, categories} = useContext(SiteContext);
    let navigate = useNavigate();
    let location = useLocation();


    const checkUnique = (email,username) => {
      if(duplicates.length > 0) {
        setDuplicates([]);
      }

     fetch(`${apiUrl}/api/users.php?action=checkunique&email=${email}&username=${username}`, {
        method: 'get'
      })
        .then((res) => res.json())
        .then((res) => {
          if(res.status === 450) {
            setDuplicates(res.msg);
            setDuplicatesMsg('found');
            } else if(res.status === 250) {
              setDuplicatesMsg(res.msg);
            }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
      
    }

    const handleSubmit = async(values, { setSubmitting, resetForm }) => {
      setUseremail(values.email);

     await checkUnique(values.email, values.username);

     setSubmitting(false)
      
      if(duplicatesMsg == 'found') {
        return;
      }
       
      let formData = new FormData();

      formData.append('email', values.email);
      formData.append('username', values.username);
      formData.append('password', values.password);
      formData.append('category', values.category);
      formData.append('terms', values.agree ? 1 : 0);
      formData.append('role', 'worker');
      formData.append('action', 'createuser');

        return fetch(`${apiUrl}/api/users.php`, {
          method: 'post',
          body: formData
        })
          .then((res) => res.json())
          .then(async (res) => {
            console.log(res);
            await localStorage.clear();
    
            if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setRkey(res.rkey);
              setShowForm(false);
              } 
              else if(res.status === 400) {
              setErrorMsg(res.msg);
              setTimeout(() => {
                setErrorMsg('');
              }, 5000);
              setSubmitting(false);
            } else {
              alert(
                    'Signup failed',
                    'Error connection to the server please check your details and try again',
                    [{ text: 'Okay' }]
                  );
            }
          })
          .catch((error) => {
            console.log('Api call error', error.message);
          });
      };
    

    return (
      <div>
        <Header />
        <div className='container'>
          <div className='row my-3 mx-auto p-2'>
            <div className='col-4 text-center text-light bg-success'>
              Step 1 of 3
            </div>  
            <div className='col-4 text-center text-dark bg-light'>
              Step 2 of 3
            </div>  
            <div className='col-4 text-center text-dark bg-light'>
              Step 3 of 3
            </div>  
          </div>

        <h3 className='my-3'>Sign up as a worker</h3>

        {msg !== '' ? <div className='my-4'><span className='p-2 my-2 text-dark'>{msg}</span></div> : null}

        {duplicates.length > 0 && <p className='text-danger my-2 p-1 border border-danger'>{duplicates.map((item,i) => <div key={i}>{item}</div>)}</p>}

          {showForm && <Formik
                    initialValues={{
                      username:'',
                      email: '',
                      category:'',
                      password: '',
                      password2: '',
                      agree: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                      <div className='row my-3 py-3'>
                        <div className='col-md-8'>
          
                        <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="email">Email address</label>
                          </div>
                          <div className='col-md-9'>
                          <Field
                            id="email"
                            name="email"
                            value={values.email}
                            onBlur={handleBlur('email')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        {touched.email && errors.email && <div className='py-1'>
                              <span className='text-danger'>{touched.email && errors.email}</span>
                        </div>}
                      </div>
                      </div>
          
                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                            <label htmlFor="category">Job category</label>
                          </div>
                          <div className='col-md-9'>
                            <Field
                              as='select'
                              id="category"
                              name="category"
                              value={values.category}
                              onBlur={handleBlur('category')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            >
                              <option value="">Select category...</option>
                              {categories && categories.map((item,i) => {
                                return <option key={i} value={item.jcat}>{item.jcat}</option>
                              })}
                            </Field>
                            
                            {touched.category && errors.category && <div className='py-1'>
                                <span className='text-danger'>{touched.category && errors.category}</span>
                          </div>}
                        </div>
                        </div>
          
          
                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="username">Username</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            id="username"
                            name="username"
                            value={values.username}
                            onBlur={handleBlur('username')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
          
                        {touched.username && errors.username && <div className='py-1'>
                              <span className='text-danger'>{touched.username && errors.username}</span>
                        </div>}
                      </div>
                      </div>
          
                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="password">Password</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            type="password"
                            id="password"
                            name="password"
                            value={values.password}
                            onBlur={handleBlur('password')}
                            secureTextEntry
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        
                        {touched.password && errors.password && <div className='py-1'>
                              <span className='text-danger'>{touched.password && errors.password}</span>
                        </div>}
                      </div>
                      </div>
          
                      <div className='form-group row my-2'>
                          <div className='col-md-3 p-2 h6'>
                          <label htmlFor="password2">Confirm Password</label>
                        </div>
                        <div className='col-md-9'>
                          <Field
                            type="password"
                            id="password2"
                            name="password2"
                            value={values.password2}
                            onBlur={handleBlur('password2')}
                            secureTextEntry
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                        
                        {touched.password2 && errors.password2 && <div className='py-1'>
                              <span className='text-danger'>{touched.password2 && errors.password2}</span>
                        </div>}
                      </div>
                      </div>
          
                      <div className='form-group row my-2'>
                          <div className='col-md-12 p-2 h6'>
                          <label htmlFor="agree"> Terms of service</label>
                        
                            <Field style={{padding: 10, margin:4}} type="checkbox" name="agree" /> {values.agree ? 'I agree' : ''}
                      </div>
                      </div>
                      
                       <button
                          type='submit'
                          onClick={handleSubmit}
                          title="Create Account"
                          disabled={!isValid || isSubmitting}
                            className='btn btn-primary'
                        >
                            Submit
                        </button>
                        </div>
                      </div>
                     
                        </form>                    )}
                  </Formik>}
      </div>
      <Footer />
    </div>
    )
}

export default Signup;